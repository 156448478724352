import { AppBar, Toolbar } from '@mui/material';
import React from 'react';
import { Account } from './Account';
import { MenuDrawer } from './MenuDrawer';

interface NavigationProps {}

export const Navigation: React.FunctionComponent<NavigationProps> = () => {
	return (
		<AppBar position="fixed">
			<Toolbar sx={{ justifyContent: 'space-between' }}>
				<MenuDrawer />
				<Account />
			</Toolbar>
		</AppBar>
	);
};
