import React from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { Person } from '@mui/icons-material';
import { useAuth } from '../../context/AuthContext';

interface AccountProps {}

export const Account: React.FunctionComponent<AccountProps> = () => {
	const menuRef = React.useRef(null);
	const [menuOpen, setMenuOpen] = React.useState(false);
	const handleMenuCLick = React.useCallback(() => setMenuOpen(true), []);
	const handleClose = React.useCallback(() => setMenuOpen(false), []);
	const { signOut } = useAuth();
	const handleLogout = React.useCallback(async () => {
		try {
			await signOut();
		} catch (error: any) {
			throw new Error(error.message as string);
		}
	}, []);

	return (
		<div ref={menuRef}>
			<IconButton size="large" edge="start" color="inherit" aria-label="settings" onClick={handleMenuCLick}>
				<Person />
			</IconButton>
			<Menu
				open={menuOpen}
				anchorEl={menuRef.current}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				onClose={handleClose}
			>
				<MenuItem onClick={handleLogout}>Ausloggen</MenuItem>
			</Menu>
		</div>
	);
};
