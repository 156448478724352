import React from 'react';
import { Stack, Skeleton, Card, CardContent } from '@mui/material';

interface PlaceholderCardProps {}

export const PlaceholderCard: React.FunctionComponent<PlaceholderCardProps> = () => (
	<Card>
		<CardContent>
			<Stack direction="row" alignItems="center" justifyContent="space-between" spacing={4}>
				<Skeleton width={40} height={40} variant="circular" />
				<Stack justifyContent="space-between" spacing={1} flexGrow={1}>
					<Skeleton variant="rectangular" width={240} height={20} />
					<Skeleton variant="rectangular" width={80} height={20} />
				</Stack>
				<Skeleton width={80} height={40} />
			</Stack>
		</CardContent>
	</Card>
);
