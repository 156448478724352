import * as React from 'react';
import { Avatar, Button, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { Person } from '@mui/icons-material';
import dayjs from 'dayjs';

interface InboxRowProps {
	id: string;
	name: string;
	phone: string;
	email: string;
	selected: boolean;
	createdAt: any;
	onClick(id: string): void;
}

export const InboxRow: React.FunctionComponent<InboxRowProps> = ({
	id,
	name,
	phone,
	email,
	selected,
	onClick,
	createdAt,
}) => {
	const handleClick = React.useCallback(() => onClick(id), []);
	return (
		<TableRow hover selected={selected}>
			<TableCell>
				<Stack direction="row" spacing={2} alignItems="center">
					<Avatar>
						<Person />
					</Avatar>
					<Typography>{name}</Typography>
				</Stack>
			</TableCell>
			<TableCell>{phone}</TableCell>
			<TableCell>{email}</TableCell>
			<TableCell>{dayjs(createdAt.toDate()).format('DD.MM.YYYY')}</TableCell>
			<TableCell>
				<Button onClick={handleClick}>Details</Button>
			</TableCell>
		</TableRow>
	);
};
