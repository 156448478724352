import { Button, Card, CardContent, Grid, TextField, FormControl, FormHelperText } from '@mui/material';
import { orange } from '@mui/material/colors';
import { Person, Lock } from '@mui/icons-material';
import { useForm, Controller } from 'react-hook-form';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { ReactComponent as Logo } from '../../assets/svg/2022-Logo-Persoply.svg';

interface LoginProps {}

export const Login: React.FunctionComponent<LoginProps> = () => {
	const { handleSubmit, control, setError, formState } = useForm();
	const { signIn, currentUser } = useAuth();
	const navigate = useNavigate();
	const handleLogin = React.useCallback(async ({ email, password }: any) => {
		try {
			await signIn(email, password);
		} catch (error: any) {
			switch (error.code) {
				case 'auth/user-not-found':
					setError('invalidUser', {
						message: 'Für diese E-Mail-Adresse gibt es keinen Nutzer.',
					});
					break;
				case 'auth/wrong-password':
					setError('wrongPassword', {
						message: 'Passwort inkorrekt',
					});
					break;

				default:
					setError('misc', {
						message: error.message ?? error,
					});
					break;
			}
		}
	}, []);

	React.useEffect(() => {
		if (currentUser) {
			navigate('./dashboard');
		}
	}, [currentUser]);

	return (
		<Grid
			container
			direction="column"
			alignItems="center"
			justifyContent="center"
			rowSpacing={4}
			mt="0"
			sx={{
				height: '100%',
				minHeight: '100vh',
				background: orange[100],
			}}
		>
			<Grid item>
				<Logo width="150px" />
			</Grid>
			<Grid item alignSelf="stretch">
				<Card sx={{ width: '100%', maxWidth: '480px', margin: '0 auto' }}>
					<CardContent>
						<form onSubmit={handleSubmit(handleLogin)}>
							<Grid container direction="column" alignItems="stretch" columns={1} rowSpacing={2}>
								<Grid item textAlign="center">
									<h2>Willkommen zurück!</h2>
									{formState.errors && (
										<FormControl error={formState.errors && true}>
											{Object.entries(formState.errors).map((entry) => {
												const { message } = entry[1] as any;
												return <FormHelperText key={entry[0]}>{message}</FormHelperText>;
											})}
										</FormControl>
									)}
								</Grid>
								<Grid item>
									<Controller
										control={control}
										name="email"
										rules={{
											required: 'E-Mail bitte füllen.',
										}}
										render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
											<TextField
												error={error !== undefined}
												helperText={error && error.message}
												type="email"
												label="E-Mail Addresse"
												InputProps={{ startAdornment: <Person sx={{ paddingRight: '10px' }} /> }}
												style={{ width: '100%' }}
												value={value ?? ''}
												onChange={onChange}
												onBlur={onBlur}
												inputRef={ref}
											/>
										)}
									/>
								</Grid>
								<Grid item>
									<Controller
										control={control}
										name="password"
										rules={{
											required: 'Passwort bitte füllen.',
										}}
										render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
											<TextField
												error={error !== undefined}
												helperText={error && error.message}
												type="password"
												label="Passwort"
												InputProps={{ startAdornment: <Lock sx={{ paddingRight: '10px' }} /> }}
												style={{ width: '100%' }}
												value={value ?? ''}
												onChange={onChange}
												onBlur={onBlur}
												inputRef={ref}
											/>
										)}
									/>
								</Grid>
								<Grid item>
									<Button color="primary" type="submit" size="large" variant="contained" style={{ width: '100%' }}>
										Login
									</Button>
								</Grid>
							</Grid>
						</form>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
