import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyDVffFlLWxMfqVm9xfw6Zld3v2BxmR6HOE',
	authDomain: 'rectruiting-dashboard.firebaseapp.com',
	projectId: 'rectruiting-dashboard',
	storageBucket: 'rectruiting-dashboard.appspot.com',
	messagingSenderId: '1025842170718',
	appId: '1:1025842170718:web:b426d816010d4773f49dcf',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost') {
	connectFirestoreEmulator(db, 'localhost', 8080);
	connectAuthEmulator(auth, 'http://localhost:9099');
}
