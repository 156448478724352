import * as React from 'react';
import { FormControl } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useFormContext, useController } from 'react-hook-form';
import dayjs from 'dayjs';

interface DatePickerProps {
	name: string;
	label: string;
	rules?: any;
}

export const DatePicker: React.FunctionComponent<DatePickerProps> = ({ name, label, rules }) => {
	const { control } = useFormContext();
	const { field } = useController({
		name,
		control,
		rules,
	});
	const { onChange, ref, onBlur, value: fieldValue } = field;
	const handlePickerChange = React.useCallback((event) => {
		onChange(dayjs(event).toString());
	}, []);

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<FormControl fullWidth>
				<DesktopDatePicker
					inputRef={ref}
					label={label}
					format="DD.MM.YYYY"
					onClose={onBlur}
					onChange={handlePickerChange}
					value={dayjs(fieldValue)}
				/>
			</FormControl>
		</LocalizationProvider>
	);
};
