import { Box, Divider, Container, Stack } from '@mui/material';
import * as React from 'react';
import { Navigation } from '../../components/Navigation';
import { CampaignImport } from './CampaignImport';

interface UserAreaProps {}

export const UserArea: React.FunctionComponent<UserAreaProps> = () => {
	return (
		<React.Fragment>
			<Navigation />
			<Box
				sx={{
					paddingTop: '32px',
					marginTop: '64px',
				}}
			>
				<Container>
					<Stack spacing={2}>
						<div>
							<Divider>Import Kampagnen Daten</Divider>
							<CampaignImport />
						</div>
					</Stack>
				</Container>
			</Box>
		</React.Fragment>
	);
};
