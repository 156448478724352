import { Grid, Card, CardContent, CardHeader } from '@mui/material';
import * as React from 'react';

import { SimpleTextField } from './Inputs/SimpleTextField';
import { SettingSelect } from './Inputs/SettingSelect';
import { DatePicker } from './Inputs/DatePicker';

interface GeneralInfoFormProps {}

const workTimeOptions = [
	{
		name: 'Vollzeit',
		value: 'full',
	},
	{
		name: 'Teilzeit',
		value: 'parttime',
	},
	{
		name: 'Ausbildung',
		value: 'apprentice',
	},
	{
		name: 'Aushilfe',
		value: 'sidejob',
	},
];

export const GeneralInfoForm: React.FunctionComponent<GeneralInfoFormProps> = () => {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Über die Stelle" />
					<CardContent>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<SimpleTextField
									name="jobTitle"
									label="Genaue Bezeichnung der Stelle"
									placeholder="z.B. Steuerfachangestellte/r"
								/>
							</Grid>
							<Grid item xs={6}>
								<SettingSelect multiple name="employmentType" label="Beschäftigungsumfang" options={workTimeOptions} />
							</Grid>
							<Grid item xs={6}>
								<SimpleTextField name="jobLocation" label="Standort der Beschäftigung" placeholder="Düsseldorf" />
							</Grid>
							<Grid item xs={6}>
								<DatePicker name="startDate" label="Kampagnenstart" />
							</Grid>
							<Grid item xs={12}>
								<SimpleTextField
									name="strongCriteria"
									tooltip="Harte Kriterien würden von uns so ausgewertet werden, dass diese zum Ausscheiden des Bewerbers führen."
									label="Harte Kriterien"
									placeholder="Verhandlungssicheres Englisch, CRM-Kenntnisse, etc..."
									minRows={4}
									multiline
								/>
							</Grid>
							<Grid item xs={12}>
								<SimpleTextField
									name="softCriteria"
									tooltip="Weiche Kriterien können Indizien für Sie über den Bewerber sein."
									label="Weiche Kriterien"
									placeholder="Pipedrive-Erfahrung, Fachkenntnisse, etc..."
									minRows={4}
									multiline
								/>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Über den Betrieb" />
					<CardContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<SimpleTextField name="companyName" label="Firmenname" placeholder="Beispiel GmbH" />
							</Grid>
							<Grid item xs={12}>
								<SimpleTextField
									name="employeeMessage"
									label="Mitarbeiterstimmen"
									placeholder="Ich arbeite gerne bei Beispiel GmbH, weil ..."
									multiline
									minRows={4}
								/>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
