import { FormControl, TextField, InputLabel, Stack, Typography, Tooltip, OutlinedInput } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import * as React from 'react';
import { useFormContext, useController } from 'react-hook-form';

interface SimpleTextFieldProps {
	name: string;
	placeholder?: string;
	label?: string;
	rules?: any;
	multiline?: boolean;
	minRows?: number;
	tooltip?: string;
}

export const SimpleTextField: React.FunctionComponent<SimpleTextFieldProps> = ({
	name,
	placeholder,
	rules,
	label,
	multiline,
	minRows,
	tooltip,
}) => {
	const { control } = useFormContext();
	const { field } = useController({
		name,
		control,
		rules,
	});

	if (tooltip) {
		return (
			<FormControl fullWidth>
				<InputLabel id={`tooltipfield-${name}`}>
					<Stack direction="row">
						<Typography>{label}</Typography>
						<Tooltip title={tooltip}>
							<InfoIcon />
						</Tooltip>
					</Stack>
				</InputLabel>
				<OutlinedInput
					label={`${label}MI`}
					name={name}
					multiline={multiline}
					minRows={minRows}
					placeholder={placeholder}
				/>
			</FormControl>
		);
	}

	return (
		<FormControl fullWidth>
			<TextField {...field} placeholder={placeholder} label={label} multiline={multiline} minRows={minRows} />
		</FormControl>
	);
};
