import { Grid, CardContent, CardHeader, Card, Typography } from '@mui/material';
import * as React from 'react';
import * as data from '../../data/benefits.json';
import { SettingSelect } from './Inputs/SettingSelect';
import { SimpleTextField } from './Inputs/SimpleTextField';

interface BenefitsProps {}

export const Benefits: React.FunctionComponent<BenefitsProps> = () => {
	return (
		<Card>
			<CardHeader title="Benefits für die Stelle" />
			<CardContent>
				<Grid container spacing={2}>
					{Array.from(data).map((benefit: any) => {
						return (
							<Grid key={benefit.name} item xs={6}>
								<SettingSelect label={benefit.title} name={`benefits.${benefit.name}`} options={benefit.options} />
							</Grid>
						);
					})}
					<Grid item xs={12}>
						<Typography marginBottom={2} marginTop={4}>
							Schreiben Sie heraus, welche der Aspekte für Ihre Branche und/oder die bei Ihnen offenen Stellen besonders
							wichtig sind und in Ihren Stellenanzeigen genannt werden sollten. Wenn Sie sich unsicher sind, fragen Sie
							gerne bei Ihren bestehenden Mitarbeitern nach. In JEDER Stellenanzeige steht sinngemäß etwas von „offener
							Unternehmenskultur“, deshalb sollten Sie überlegen, wie das Ganze konkreter oder anders formuliert werden
							könnte.
						</Typography>
						<SimpleTextField
							name="benefit.special"
							label="Was grenzt dein Unternehmen ab?"
							placeholder="Verhandlungssicheres Englisch, CRM-Kenntnisse, etc..."
							multiline
							minRows={4}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};
