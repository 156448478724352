import * as React from 'react';
import { Drawer, IconButton, List, ListItem, ListItemText, Box } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { Link } from 'react-router-dom';

interface MenuDrawerProps {}

export const MenuDrawer: React.FunctionComponent<MenuDrawerProps> = () => {
	const [open, setOpen] = React.useState(false);
	const handleMenuToggle = React.useCallback(() => setOpen(!open), [open]);

	return (
		<React.Fragment>
			<IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={handleMenuToggle}>
				<Menu />
			</IconButton>
			<Drawer anchor="left" open={open} onClose={handleMenuToggle}>
				<Box sx={{ width: 250 }} role="presentation">
					<List>
						<Link to="/dashboard" style={{ textDecoration: 'none' }}>
							<ListItem>
								<ListItemText primary="Dashboard" />
							</ListItem>
						</Link>
						<Link to="/dashboard/campaigns" style={{ textDecoration: 'none' }}>
							<ListItem>
								<ListItemText primary="Kampagnen" />
							</ListItem>
						</Link>
					</List>
				</Box>
			</Drawer>
		</React.Fragment>
	);
};
