import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { SignUp } from './components/SignUp';
import { Login } from './features/Login';
import { Dashboard } from './features/Dashboard';
import { AuthProvider } from './context/AuthContext';
import { UserArea } from './features/UserArea';

interface AppProps {}

const App: React.FunctionComponent<AppProps> = () => {
	const theme = createTheme({
		palette: {
			primary: {
				main: '#f4511e',
			},
			secondary: {
				main: '#1a237e',
			},
			background: {
				default: '#fef3ee',
			},
		},
	});

	return (
		<AuthProvider>
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<Login />} />
						<Route path="/user/*" element={<UserArea />} />
						{/* <Route path="/signup" element={<SignUp />} /> */}
						<Route path="/dashboard/*" element={<Dashboard />} />
					</Routes>
				</BrowserRouter>
			</ThemeProvider>
		</AuthProvider>
	);
};

export default App;
