import { Card, CardContent, Container, Grid, Typography, Box, Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

interface OverviewProps {}

export const Overview: React.FunctionComponent<OverviewProps> = () => {
	return (
		<Container maxWidth={false}>
			<Box
				sx={{
					padding: '20px',
				}}
			>
				<Grid container justifyItems="stretch" spacing={2}>
					<Grid item xs={6}>
						<Card>
							<CardContent>
								<Typography>Willkommen bei Persoply</Typography>
								<Link to="./campaigns" style={{ textDecoration: 'none' }}>
									<Button variant="text">Deine Kampagne</Button>
								</Link>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
};
