import { Box, useTheme } from '@mui/material';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Campaigns } from '../Campaigns';
import { Navigation } from '../../components/Navigation';
import { Overview } from './Overview';

interface DashboardProps {}

export const Dashboard: React.FunctionComponent<DashboardProps> = () => {
	const theme = useTheme();

	return (
		<React.Fragment>
			<Navigation />
			<Box
				sx={{
					paddingTop: '64px',
					bgcolor: theme.palette.background.default,
					minHeight: 'calc(100vh - 64px)',
				}}
			>
				<Routes>
					<Route path="/*" element={<Overview />} />
					<Route path="/campaigns/*" element={<Campaigns />} />
				</Routes>
			</Box>
		</React.Fragment>
	);
};
