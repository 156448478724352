import React from 'react';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { LinkButton } from '../../components/LinkButton';

interface CampaignCardProps {
	campaignId: string;
	companyName: string;
	imgSrc?: string;
	name: string;
}

export const CampaignCard: React.FunctionComponent<CampaignCardProps> = ({ campaignId, companyName, imgSrc, name }) => (
	<ListItem
		secondaryAction={
			<React.Fragment>
				<LinkButton to={`./${campaignId}/applicants`}>Bewerber</LinkButton>
				<LinkButton to={`./${campaignId}/settings`}>Einstellungen</LinkButton>
			</React.Fragment>
		}
	>
		<ListItemAvatar>
			<Avatar alt={name} src={imgSrc} />
		</ListItemAvatar>
		<ListItemText primary={name} secondary={companyName} />
	</ListItem>
);
