/* eslint-disable no-lonely-if */
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { db } from '../../firebase';

interface CampaignImportProps {}

export const CampaignImport: React.FunctionComponent<CampaignImportProps> = () => {
	const { register, handleSubmit } = useForm();
	const [loading, setLoading] = React.useState(false);
	const onSubmit = React.useCallback((formData) => {
		setLoading(true);
		const fileReader = new FileReader();
		if (fileReader) {
			fileReader.onload = (e) => {
				const content = e.target && JSON.parse(e.target.result as string);
				if (content) {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					Object.entries(content).forEach(async ([_key, data]) => {
						try {
							const { campaignId, applicants, ...rest } = data as any;
							let docRef;
							if (campaignId) {
								docRef = doc(db, 'campaigns', campaignId);

								await updateDoc(docRef, rest);
							} else {
								const colRef = collection(db, 'campaigns');
								docRef = await addDoc(colRef, rest);
							}

							if (applicants) {
								const applicantsRef = collection(db, 'campaigns', docRef.id, 'applicants');

								applicants.forEach(async ({ phone, email, name, status, ...restData }) => {
									const questions = JSON.stringify(
										Object.entries(restData).reduce((acc: { title: string; value: string }[], [title, value]) => {
											const newEntry = { title, value };
											acc.push(newEntry);

											return acc;
										}, []),
									);

									await addDoc(applicantsRef, {
										phone,
										email,
										name,
										status,
										questions,
									});
								});
							}
							setLoading(false);
						} catch (error) {
							throw new Error(error);
						}
					});
				}
			};

			fileReader.readAsText(formData.file[0], 'UTF-8');
		}
	}, []);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{loading && 'loading'}
			<input type="file" placeholder="file" {...register('file', {})} />
			<input type="submit" />
		</form>
	);
};
