import { Box } from '@mui/material';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CampaignInbox } from '../CampaignInbox';
import { CampaignSettings } from '../CampaignSettings';
import { ListView } from './ListView';

interface CampaignsProps {}

export const Campaigns: React.FunctionComponent<CampaignsProps> = () => {
	return (
		<Box margin={4}>
			<Routes>
				<Route path="/" element={<ListView />} />
				<Route path="/:id/applicants" element={<CampaignInbox />} />
				<Route path="/:id/settings" element={<CampaignSettings />} />
			</Routes>
		</Box>
	);
};
