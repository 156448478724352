import * as React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

interface LinkButtonProps {
	to: string;
	children?: React.ReactNode;
	variant?: 'outlined' | 'text' | 'contained';
}

export const LinkButton: React.FunctionComponent<LinkButtonProps> = ({ to, children, variant = 'text' }) => (
	<Link to={to} style={{ textDecoration: 'none' }}>
		<Button variant={variant}>{children}</Button>
	</Link>
);
