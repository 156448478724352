import { List, Card, Divider, Paper } from '@mui/material';
import React from 'react';
import { useFirestore } from '../../hooks/useFirestore';
import { CampaignCard } from './CampaignCard';
import { PlaceholderCard } from './PlaceholderCard';
import { useAuth } from '../../context/AuthContext';

interface ListViewProps {}

export const ListView: React.FunctionComponent<ListViewProps> = () => {
	const { currentUser } = useAuth();
	const [campaigns, setCampaigns] = React.useState<any[] | undefined>();
	useFirestore(async (db, { collection, where, query, getDocs }) => {
		if (currentUser) {
			try {
				const campaignQuery = query(collection(db, 'campaigns'), where('user', 'array-contains', currentUser.uid));
				const snapShot = await getDocs(campaignQuery);
				setCampaigns(snapShot.docs as any);
			} catch (error) {
				throw new Error(error);
			}
		}
	});

	return (
		<Paper elevation={0}>
			<Card>
				<List>
					{campaigns ? (
						campaigns.map((doc, index, array) => {
							const content = doc.data();

							return (
								<React.Fragment>
									<CampaignCard
										key={`${content.name}-${doc.id}`}
										campaignId={doc.id}
										companyName={content.companyName}
										name={content.jobTitle}
										imgSrc={content.companyLogo}
									/>
									{!(index + 1 === array.length) && <Divider variant="middle" />}
								</React.Fragment>
							);
						})
					) : (
						<React.Fragment>
							<PlaceholderCard />
							<PlaceholderCard />
							<PlaceholderCard />
						</React.Fragment>
					)}
				</List>
			</Card>
		</Paper>
	);
};
