import { FormControl, InputLabel, Select, MenuItem, OutlinedInput, Checkbox, ListItemText } from '@mui/material';
import * as React from 'react';
import { useFormContext, useController } from 'react-hook-form';

interface SettingSelectProps {
	name: string;
	label: string;
	// @todo Find prettier/eslint rule to enable semicolon
	options: { name: string; value: string }[];
	multiple?: boolean;
	rules?: any;
}

export const SettingSelect: React.FunctionComponent<SettingSelectProps> = ({
	multiple,
	name,
	label,
	options,
	rules,
}) => {
	const { control } = useFormContext();
	const { field } = useController({
		defaultValue: multiple ? [] : '',
		name,
		control,
		rules,
	});

	if (multiple) {
		return (
			<FormControl fullWidth>
				<InputLabel id={`label-${name}`}>{label}</InputLabel>
				<Select
					{...field}
					labelId={`label-${name}`}
					multiple={multiple}
					input={<OutlinedInput label={label} />}
					renderValue={(selected) => {
						const selectedNames = selected.map(
							(selectedValue) => options.find(({ value: optionValue }) => selectedValue === optionValue)?.name,
						);

						return selectedNames.join(', ');
					}}
				>
					{options.map(({ name: optionName, value: optionValue }) => (
						<MenuItem key={optionName} value={optionValue}>
							<Checkbox checked={field.value.indexOf(optionValue) > -1} />
							<ListItemText primary={optionName} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		);
	}

	return (
		<FormControl fullWidth>
			<InputLabel id={`label-${name}`}>{label}</InputLabel>
			<Select {...field} labelId={`label-${name}`} multiple={multiple} input={<OutlinedInput label={label} />}>
				{options.map(({ name: optionName, value: optionValue }) => (
					<MenuItem key={optionName} value={optionValue}>
						<ListItemText primary={optionName} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
