import { Grid, CardContent, CardHeader, Card } from '@mui/material';
import * as React from 'react';
import { SimpleTextField } from './Inputs/SimpleTextField';

interface ComunicationProps {}

export const Comunication: React.FunctionComponent<ComunicationProps> = () => {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Kontakt für Bewerber" />
					<CardContent>
						<Grid container spacing={2}>
							<Grid item xs={3}>
								<SimpleTextField
									name="contactPerson.name"
									label="Ansprechpartner Für Bewerber"
									placeholder="Max Mustermann"
								/>
							</Grid>
							<Grid item xs={3}>
								<SimpleTextField
									name="contactPerson.email"
									label="E-Mail-Adresse für Bewerbungseingang"
									placeholder="info@persoply.com"
								/>
							</Grid>
							<Grid item xs={12}>
								<SimpleTextField
									name="notification.successMailTemplate"
									label="Template für Bestätigungsemail"
									multiline
									minRows={3}
								/>
							</Grid>
							<Grid item xs={12}>
								<SimpleTextField
									name="notification.declineMailTemplate"
									label="Template für Absage"
									multiline
									minRows={3}
								/>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
