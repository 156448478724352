import {
	Button,
	Container,
	Box,
	Stack,
	Tabs,
	Tab,
	Typography,
	Toolbar,
	CircularProgress,
	Snackbar,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as React from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { Benefits } from './Benefits';
import { GeneralInfoForm } from './GeneralInfoForm';
import { Comunication } from './Comunication';
import { db } from '../../firebase';

interface CampaignSettingsProps {}

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

export const CampaignSettings: React.FunctionComponent<CampaignSettingsProps> = () => {
	const methods = useForm();
	const [loading, setLoading] = React.useState(true);
	const { id: campaignId } = useParams();
	const [activeTab, setActiveTab] = React.useState(0);
	const docRef = campaignId && doc(db, 'campaigns', campaignId);
	const handleTabChange = React.useCallback((_event: React.SyntheticEvent, newActiveTab: number) => {
		setActiveTab(newActiveTab);
	}, []);
	const handleSettingSubmit = React.useCallback(
		async (formData) => {
			setLoading(true);
			const cleanData = Object.entries(formData).reduce((acc, [key, value]) => {
				let filteredData;
				switch (typeof value) {
					case 'object':
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						filteredData = Object.entries(value as object).filter(([_key, childValue]) => childValue);
						if (filteredData.length > 0) {
							acc[key] = Object.fromEntries(filteredData);
						}
						break;
					case 'undefined':
						break;
					default:
						acc[key] = value;
						break;
				}

				return acc;
			}, {});

			try {
				if (docRef) {
					await updateDoc(docRef, cleanData);
					setLoading(false);
				}
			} catch (e) {
				throw new Error(e);
			}
		},
		[campaignId],
	);

	React.useEffect(() => {
		async function getSettingData() {
			try {
				if (docRef) {
					const settingDoc = await getDoc(docRef);
					const settingData = settingDoc.data();
					if (settingData) {
						if (settingData.user) {
							delete settingData.user;
						}
						Object.entries(settingData).forEach(([key, value]) => {
							switch (typeof value) {
								case 'object':
									Object.entries(value).forEach(([childKey, childValue]) =>
										methods.setValue(`${key}.${childKey}`, childValue),
									);
									break;
								default:
									methods.setValue(key, value);
									break;
							}
						});
					}
					setLoading(false);
				}
			} catch (e) {
				throw new Error(e);
			}
		}

		getSettingData();
	}, []);

	return (
		<form onSubmit={methods.handleSubmit(handleSettingSubmit)}>
			<Container>
				<Snackbar open={loading} autoHideDuration={6000} message="Laden..." />
				{loading ? (
					<Stack direction="row" justifyContent="center" alignItems="center">
						<CircularProgress />
					</Stack>
				) : (
					<Stack spacing={4}>
						<Box>
							<Toolbar>
								<Typography variant="h4" sx={{ flexGrow: 1 }}>
									Einstellungen
								</Typography>
								<Stack direction="row" spacing={2}>
									<Button type="submit" variant="contained">
										Speichern
									</Button>
									<Link to="./../applicants" style={{ textDecoration: 'none' }}>
										<Button variant="outlined">Bewerber</Button>
									</Link>
								</Stack>
							</Toolbar>
							<Tabs value={activeTab} onChange={handleTabChange}>
								<Tab label="Generelle Informationen" {...a11yProps(0)} />
								<Tab label="Benefits" {...a11yProps(1)} />
								<Tab label="Kommunikation" {...a11yProps(2)} />
							</Tabs>
						</Box>
						<FormProvider {...methods}>
							<TabPanel index={0} value={activeTab}>
								<GeneralInfoForm />
							</TabPanel>
							<TabPanel index={1} value={activeTab}>
								<Benefits />
							</TabPanel>
							<TabPanel index={2} value={activeTab}>
								<Comunication />
							</TabPanel>
						</FormProvider>
					</Stack>
				)}
			</Container>
		</form>
	);
};
