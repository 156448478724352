import {
	Box,
	Button,
	CircularProgress,
	Grid,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { onSnapshot, query, collection, CollectionReference, where, orderBy } from 'firebase/firestore';
import { Person } from '@mui/icons-material';
import { InboxRow } from './InboxRow';
import { db } from '../../firebase';
import { InboxDetails } from './InboxDetails';

interface CampaignInboxProps {}

interface PersonData {
	name: string;
	email: string;
	locationCity: string;
	phone?: string;
	status?: 'new' | 'unfit' | 'denied' | 'employed' | 'in-progress';
	questions: string;
	createdAt: any;
}

interface Person extends PersonData {
	id: string;
}

export const CampaignInbox: React.FunctionComponent<CampaignInboxProps> = () => {
	const { id: campaignId } = useParams();
	const [response, setResponse] = React.useState<Person[]>([]);
	const [activeEntry, setActiveEntry] = React.useState('');
	const details = React.useMemo(
		() => response.find((responseEntry) => responseEntry.id === activeEntry),
		[activeEntry],
	);

	React.useEffect(() => {
		const unsubscribe = onSnapshot<PersonData>(
			query<PersonData>(
				collection(db, 'campaigns', campaignId as string, 'applicants') as CollectionReference<PersonData>,
				where('status', 'not-in', ['denied', 'unfit']),
				orderBy('status'),
				orderBy('createdAt', 'desc'),
			),
			(querySnapshot) => {
				const applicants: Person[] = [];

				querySnapshot.forEach((result) => {
					applicants.push({ id: result.id, ...result.data() });
				});

				setResponse(applicants);
			},
		);

		return () => unsubscribe();
	}, []);

	React.useEffect(() => {
		if (response.length > 0 && !activeEntry) {
			setActiveEntry(response[0].id);
		}
	}, []);

	return (
		<Box paddingBottom={6}>
			{response ? (
				<Grid container spacing={4}>
					<Grid xs={12} item>
						<Stack direction="row" alignItems="center" justifyContent="space-between">
							<Stack>
								<Typography variant="h4">Campaign Name</Typography>
								<Typography>Campaign ID:</Typography>
							</Stack>
							<Link to="./../settings" style={{ textDecoration: 'none' }}>
								<Button variant="outlined">Settings</Button>
							</Link>
						</Stack>
					</Grid>
					<Grid xs={8} item>
						<Paper>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell>Telefon</TableCell>
										<TableCell>E-Mail</TableCell>
										<TableCell>Eingang</TableCell>
										<TableCell />
									</TableRow>
								</TableHead>
								<TableBody>
									{response.map((candidate) => (
										<InboxRow
											key={candidate.id}
											phone={candidate.phone ?? '-'}
											name={candidate.name}
											email={candidate.email}
											createdAt={candidate.createdAt}
											id={candidate.id}
											onClick={setActiveEntry}
											selected={candidate.id === activeEntry}
										/>
									))}
								</TableBody>
							</Table>
						</Paper>
					</Grid>
					{details && <InboxDetails details={details} />}
				</Grid>
			) : (
				<CircularProgress />
			)}
		</Box>
	);
};
