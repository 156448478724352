import { Card, CardActions, CardContent, Divider, Grid, List, ListItem, ListItemText } from '@mui/material';
import * as React from 'react';
import { StatusForm } from './StatusForm';

interface InboxDetailsProps {
	details: any;
}

export const InboxDetails: React.FunctionComponent<InboxDetailsProps> = ({ details }) => (
	<Grid item xs={4}>
		<Card>
			<CardContent>
				<List>
					<ListItem>
						<ListItemText
							primary={details.name}
							primaryTypographyProps={{
								fontSize: 24,
								fontWeight: 'medium',
								lineHeight: '20px',
								mb: '2px',
							}}
						/>
					</ListItem>
					<ListItem>
						<ListItemText primary="Telefon" secondary={details.phone} />
					</ListItem>
					<Divider variant="middle" />
					<ListItem>
						<ListItemText primary="E-Mail" secondary={details.email} />
					</ListItem>
					<Divider variant="middle" />
					{details.questions &&
						JSON.parse(details.questions)
							.filter(
								({ value }) =>
									// @todo please fix this BS
									![details.email, details.phone, details.name, 'Danke', 'Mehr erfahren', 'Ja, absolut!'].includes(
										value,
									),
							)
							.map(({ title, value }) => {
								let processedValue = value ? 'Ja' : 'Nein';
								processedValue = typeof value === 'string' ? value : processedValue;

								return (
									<React.Fragment>
										<ListItem key={`${title}-${value}`}>
											<ListItemText primary={title} secondary={processedValue} />
										</ListItem>
										<Divider variant="middle" />
									</React.Fragment>
								);
							})}
				</List>
			</CardContent>
			<CardActions>
				<StatusForm
					applicantName={details.name}
					applicantMail={details.email}
					status={details.status}
					id={details.id}
				/>
			</CardActions>
		</Card>
	</Grid>
);
