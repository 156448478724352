import {
	Stack,
	FormControl,
	InputLabel,
	Select,
	OutlinedInput,
	MenuItem,
	ListItemText,
	Button,
	CircularProgress,
} from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import axios from 'axios';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';

const phases = [
	{ value: 'new', label: 'Neuer Bewerber' },
	{ value: 'unfit', label: 'Ungeeignet' },
	{ value: 'denied', label: 'Abgelehnt' },
	{ value: 'in-progress', label: 'Im Gespräch' },
	{ value: 'employed', label: 'Eingestellt' },
];

interface StatusFormProps {
	id: string;
	applicantName: string;
	applicantMail: string;
	status?: 'new' | 'unfit' | 'denied' | 'employed' | 'in-progress';
}

export const StatusForm: React.FunctionComponent<StatusFormProps> = ({ status, id, applicantName, applicantMail }) => {
	const { id: campaignId } = useParams();
	const { handleSubmit, control } = useForm({
		values: {
			status: status ?? 'new',
		},
	});
	// @todo Totally dumb! let this happen somewhere higher or make use of context API to do this
	const handleStatusUpdate = React.useCallback((formData) => {
		async function sendNotificationPost() {
			if (formData.status !== status && campaignId) {
				const docRef = doc(db, 'campaigns', campaignId, 'applicants', id);
				await updateDoc(docRef, {
					status: formData.status,
				});

				if (['unfit', 'denied'].includes(formData.status)) {
					axios.post('https://hook.eu1.make.com/abwl2d2u73pchtfp7ll8fidv6xjuvu13', {
						campaignId,
						email: applicantMail,
						name: applicantName,
					});
				}
			}
		}

		sendNotificationPost();
	}, []);

	return (
		<form onSubmit={handleSubmit(handleStatusUpdate)}>
			<Stack direction="row" alignItems="center" gap={2}>
				<Controller
					control={control}
					name="status"
					render={({ field }) => (
						<FormControl fullWidth>
							{!field.value ? (
								<CircularProgress />
							) : (
								<React.Fragment>
									<InputLabel id="status-label">Status</InputLabel>
									<Select
										{...field}
										labelId="status-label"
										label="Status"
										input={<OutlinedInput value={field.value} label="Status" />}
									>
										{phases.map(({ value: optionValue, label: optionLabel }) => (
											<MenuItem key={optionValue} value={optionValue}>
												<ListItemText primary={optionLabel} />
											</MenuItem>
										))}
									</Select>
								</React.Fragment>
							)}
						</FormControl>
					)}
				/>
				<Button type="submit" variant="contained">
					Speichern
				</Button>
			</Stack>
		</form>
	);
};
