import React from 'react';
import {
	createUserWithEmailAndPassword,
	onAuthStateChanged,
	User,
	signInWithEmailAndPassword,
	signOut as firebaseSignout,
} from 'firebase/auth';
import { auth } from '../firebase';

interface AuthProviderProps {
	children: React.ReactNode;
}

interface UseAuthReturn {
	signUp(email: string, password: string): void;
	signIn(email: string, password: string): void;
	signOut(): void;
	currentUser?: User;
}

const signUp = async (email: string, password: string) => {
	await createUserWithEmailAndPassword(auth, email, password);
};

const signIn = async (email: string, password: string) => {
	await signInWithEmailAndPassword(auth, email, password);
};

const signOut = async () => {
	await firebaseSignout(auth);
};

const baseObject = {
	signIn,
	signUp,
	signOut,
};

const AuthContext = React.createContext<UseAuthReturn>(baseObject);

export function useAuth() {
	return React.useContext(AuthContext);
}
export const AuthProvider: React.FunctionComponent<AuthProviderProps> = ({ children }) => {
	const [currentUser, setCurrentUser] = React.useState<User>();

	const value = React.useMemo(() => {
		const user = {
			...baseObject,
			currentUser,
		};
		return user;
	}, [currentUser]);
	React.useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => user && setCurrentUser(user));

		return unsubscribe;
	}, []);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
