import React from 'react';
import * as firestoreMethods from 'firebase/firestore';
import { db } from '../firebase';

export function useFirestore(
	callback: (database: firestoreMethods.Firestore, methods: typeof firestoreMethods) => Promise<void>,
) {
	React.useEffect(() => {
		async function getFirebaseDocuments() {
			try {
				await callback(db, firestoreMethods);
			} catch (error) {
				throw new Error(error.message);
			}
		}

		getFirebaseDocuments();
	}, []);
}
